import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Header } from "./header"
import { Footer } from "./footer"

type Props = {
  children: React.ReactNode
}

const Layout: React.VFC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="container py-3">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
