/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import OGP from '../images/ogp.png'

function Seo() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            searchDescription
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const title = site.siteMetadata.title
  const seoTitle = `${title} | ${site.siteMetadata.description}`
  const siteUrl = site.siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja_JP'
      }}
      title={seoTitle}
      titleTemplate={seoTitle}
      meta={[
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          name: `description`,
          content: site.siteMetadata.searchDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${OGP}`,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:locale`,
          content: 'ja_JP',
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
      ]}
    />
  )
}

export default Seo
