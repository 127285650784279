import { Link } from "gatsby"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Header: React.VFC = () => {
  return (
    <header>
      <div
        className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom"
      >
        <Link className="d-flex align-items-center text-dark text-decoration-none" to="/">
          <StaticImage
            src="../images/logo.png"
            width={120}
            quality={95}
            alt="株式会社Fortune"
            placeholder="none"
          />
        </Link>

        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
          <a className="py-2 text-dark text-decoration-none" href="#main-vision">ビジョン</a>
          <a className="ms-3 py-2 text-dark text-decoration-none" href="#company-data">会社情報</a>
          <a className="ms-3 py-2 text-dark text-decoration-none" href="#business-details">事業内容</a>
          <a className="ms-3 py-2 text-dark text-decoration-none" href="#contact-form">お問い合わせ</a>
        </nav>
      </div>
    </header>
  )
}